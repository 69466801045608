// this script is for the smooth scrolling behavior on the 3 main landing pages.


$(function() {    
  
  $('ul.nav a').bind('click',function(event){
    var $anchor = $(this);
    var current = $('ul.nav li.active');
    var lastAnchor = $('ul.nav li:last').find("a").attr("href");
      
    $('ul.nav li').removeClass("active");
    
    if($(this).parent().attr("id")=="arrow_up"){
      if( current.find("a").attr("href") == "#section1" ){ 
        current.addClass("active"); 
        return false; 
      }
      $anchor = current.prev().find("a");
      current.prev().addClass("active");  
    }else if($(this).parent().attr("id")=="arrow_down"){
      if( current.find("a").attr("href") == lastAnchor ){
        current.addClass("active"); 
        return false; 
      }
      $anchor = current.next().find("a");
      current.next().addClass("active");
    }else{
      $(this).parent().addClass("active");
    }    
    var scrollToPosition = parseInt($($anchor.attr('href')).offset().top) - parseInt($("div.fixed_header").outerHeight() + 35);    
    //$('html, body').stop().animate({ scrollTop: scrollToPosition }, 1500,'easeInOutExpo');
    // changed speed from original..
    $('html, body').stop().animate({ scrollTop: scrollToPosition }, 500 );
    event.preventDefault();
  });

	$('#scroller .section').waypoint( function( event , direction ) {
		$('ul.nav li').removeClass('active')
		$('.anchor_' + $(this).attr('id')).addClass('active')
	} , {
		offset: 290
	})
});