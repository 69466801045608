/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

export const Rails = require('rails-ujs')
Rails.start()

import "core-js/stable";
import "regenerator-runtime/runtime";

require('jquery')

import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ja';
datepickerFactory($);
datepickerJAFactory($);

import fancybox from 'fancybox';

import flatpickr from "flatpickr";


// import '../javascript/vendor/jwplayer'; // TODO: DOESN'T WORK http://localhost:3000/contacts/video

import '../javascript/vendor/jquery.easing.1.3';
import '../javascript/vendor/jquery.fancybox.pack';
import '../javascript/vendor/superfish.min';
import waypoints from '../javascript/vendor/waypoints.min';

import '../javascript/home.js';
import '../javascript/jobs.js';
import '../javascript/lightbox_links.js';
import '../javascript/reports.js';
import '../javascript/scroller.js';


/**
 * Refers to app/javascript/stylesheets/application.scss (or application.css)
 * Note that we don't need to preface this path with "app/javascript" due to the `source_path` config set in config/webpacker.yml. Magical!
 * The file extension can be left off due to the `extensions` config in config/webpacker.yml.
 */
import "stylesheets/application";
require("flatpickr/dist/themes/light.css")
import "../stylesheets/boilerplate.css";
import "../stylesheets/general.scss";
import "../stylesheets/grid.scss";
import "../stylesheets/forms.scss";
import "../stylesheets/clients.scss";
import "../stylesheets/contacts";
import "../stylesheets/home";
import "../stylesheets/jobseekers";
import "../stylesheets/superfish";
import "../stylesheets/vertical";
import "../stylesheets/jquery.fancybox";
import "../stylesheets/jquery.fancybox_overrides";


const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

