$(document).on('submit', '#jobseeker_form', function(e) {
  e.preventDefault();
  url = $(this).attr('action');
  hiddenPosition = $('#primary-job-listing')
  positions = ""

  if(hiddenPosition.length > 0) {
    positions += ("&positions[]=" + hiddenPosition.val())
  }

  $(this).find('input[type="checkbox"]:checked').each(function () {
    positions += ("&positions[]=" + $(this).val());
  })

  branchCode = $(this).find('#branch_code').val();
  parent.location.href = url + "?branch_code=" + branchCode + positions;
})

document.addEventListener('DOMContentLoaded', function () {
  const iframe = document.querySelector('.responsive-iframe');

  if (iframe) {
    window.addEventListener('message', function (event) {
      let height = event.data.height;

      if (height) {
        iframe.style.height = height + 'px';
        iframe.style.width = '100vw';
        window.scroll({
          top: 0,
          left: 0,
          behavior:'smooth'
        });
      }
    });
  }
})
