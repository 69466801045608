
// initialise plugin - main drop-down menu
$(document).ready(function () {
    $("ul.sf-menu").superfish({
        animation: {height: 'show'},   // slide-down effect without fade-in
        speed: 200,
        delay: 200               //  delay on mouseout
    });

});


// **** toggle mobile nav **** //
$(document).ready(function () {
  $( "a#toggle-mobile-nav" ).click(function( event ) {
    $("#mobile-nav").slideToggle();
    event.preventDefault();
    });
});



// ***** Home Page - vertically center content *****
/*
$(document).ready(function () {

  if ($('#home_container').length == 1) {

    // figures out how much extra space there is, and adds it above & below the 3 main panels
    centerContent = function() {
      var extraSpace = $(window).height() - 680;  // ( 680 is the height of all the content )
      if (extraSpace > 0) {
        $('.home_panels').css( "margin-top", Math.floor(extraSpace * .33) );
        $('.home_panels').css( "margin-bottom", Math.floor(extraSpace * .66) );
      }
    }

    centerContent();
    $(window).on('resize', function(){
        centerContent();
    });

  }

});
*/

$(document).ready(function() {
  var dateToday = new Date();
  $('.datepicker').datepicker({ changeYear: true, dateFormat: "MM d, yy", yearRange: "-100:+5" });
});

