$(document).ready(function() {

//  $('.fancybox').fancybox();  // old default

  $('.fancybox').fancybox({
    helpers : {
     overlay : {locked: false}  // fixes the 'page-shift' issue
    }
  });  // new default  -- used by header links

  $(".fancybox_form_link").fancybox({
    fitToView : false,
    width : '95%',
    maxWidth: 1000,
    height : '90%',
    scrolling : 'auto',
    padding : 13,
    autoSize : false,
    closeClick : false,
    openEffect : 'none',
    type : 'iframe',
    closeEffect : 'none',
    helpers : {
     overlay : {closeClick: false}
   }

 });


  $(".fancybox_interview").fancybox({
    fitToView : false,
    height : '90%',
    width : '95%',
    //width : 1150,
    maxWidth : 1150,
    scrolling : 'yes',
    padding : 5,
    autoSize : false,
    closeClick : false,
    openEffect : 'none',
    type : 'iframe',
    closeEffect : 'none',
    helpers : {
      overlay : {closeClick: false}
    }

  });


  $(".fancybox_video_link").fancybox({
    fitToView : false,
    width : 640,
    height : 370,
    padding : 10,
    autoSize : false,
    closeClick : false,
    openEffect : 'none',
    type : 'iframe',
    scrolling : 'no',
    closeEffect : 'none',
    helpers : {
     overlay : {closeClick: false, opacity: 0.85}
   }

 });

$('.fancybox_application_link').fancybox({
    fitToView : false,
    width : 500,
    height : 700,
    padding : 10,
    autoSize : false,
    closeClick : false,
    openEffect : 'none',
    type : 'iframe',
    scrolling : 'no',
    closeEffect : 'none',
    helpers : {
     overlay : {closeClick: false, opacity: 0.85}
  }
});


});
