document.addEventListener('DOMContentLoaded', function() {
  flatpickr('.datetime-picker', {
    altInput: true,
    altFormat: "F j, Y h:i K",
    dateFormat: "Y-m-d H:i",
    enableTime: true
  });

  flatpickr('.date-picker', {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    enableTime: false
  });

})

$(document).ready(function () {
  $('.number-required, .number-clocked-in').on('change paste keyup', function(e) {
    e.preventDefault();

    var numberRequired = 0;
    var numberClockedIn = 0;
    $('.number-required').each(function(index) {
      numberRequired += (parseInt($(this).val()) || 0);
    })

    $('.number-clocked-in').each(function(index) {
      numberClockedIn += (parseInt($(this).val()) || 0);
    })

    $('#required-total').text(numberRequired);
    $('#clockedin-total').text(numberClockedIn);

    if(numberRequired === 0) {
        $('#total-percentage b').text('0%');
    } else {
        percentage = ((numberClockedIn / numberRequired) * 100).toFixed(2);
        $('#total-percentage b').text(percentage + '%');
    }
  })

  var canvas = document.getElementById('signaturePanel');
  if(canvas) {
    var signaturePad = new SignaturePad(canvas, {
      backgroundColor: 'rgb(255, 255, 255)',
      onEnd: function () {
        $.get('/staff-count/get_current_date', function(data) {
          $('.date-and-time').val(data.formatted_date);
          $('.hidden-date-and-time').val(data.date);
        })
      }
    });

    document.getElementById('clearSignature').addEventListener('click', function(e) {
      e.preventDefault();
      signaturePad.clear();
    })

    $('.new_report').on('submit', function(e){
      if (!signaturePad.isEmpty()) {
        signature = signaturePad.toDataURL();
        $('#report_client_representative_signature').val(signature);
      }

      return;
    })


  }

  var invalidChars = [
    "-",
    "+",
    "e",
  ];

  document.querySelectorAll('.report-number').forEach(item => {
    item.addEventListener("keydown", function(e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
  })

})


